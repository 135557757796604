var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Signer = void 0;
/**
 * General signing interface, can be used for in memory signing, RPC singing, external wallet, HSM, etc.
 */
class Signer {}
exports.Signer = Signer;
export default exports;